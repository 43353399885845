import React, { useState } from 'react';
import { navigate, useLocation } from '@reach/router';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { LayoutPaths } from '@/pages/routers';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import DropdownMenu from '@/components/DropdownMenu';
import ModalConfirm from '@/containers/ModalConfirm';
import { EBannerType } from '@/components/Banner';
import { getMyProfileAction } from '@/redux/actions';
import Helpers from '@/services/helpers';
import Avatar from '@/components/Avatar';
import { TRootState } from '@/redux/reducers';
import { dataMenuBar } from '@/containers/SideBar/SideBar.data';
import Button, { EButtonStyleType } from '@/components/Button';

import { THeaderProps } from './Header.types.d';
import './Header.scss';

const Header: React.FC<THeaderProps> = ({ isMobile, onOpenMenu }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [visibleModalLogout, setVisibleModalLogout] = useState<boolean>(false);

  const myProfileState = useSelector((state: TRootState) => state.authReducer.getMyProfileResponse);
  const vovMyProfileState = useSelector((state: TRootState) => state.vovAuthReducer.vovGetMyProfileResponse);

  const generateGreetings = (): string => {
    const currentHour = Number(moment().format('HH'));
    if (currentHour >= 3 && currentHour < 12) {
      return 'Chào buổi sáng';
    }
    if (currentHour >= 12 && currentHour < 18) {
      return 'Chào buổi chiều';
    }
    if (currentHour >= 18 && currentHour < 20) {
      return 'Chào buổi tối';
    }
    return 'Xin chào';
  };

  const handleOpenModalLogout = (): void => {
    setVisibleModalLogout(true);
  };

  const handleCloseModalLogout = (): void => {
    setVisibleModalLogout(false);
  };

  const handleLogout = (): void => {
    dispatch(getMyProfileAction.success(undefined));
    Helpers.clearTokens();
    navigate(LayoutPaths.Auth);
  };

  const dataSettingsDropdown = [
    {
      value: 'user-information',
      label: 'Thông tin thành viên',
      iconName: EIconName.User,
      disabled: true,
    },
    {
      value: 'logout',
      label: 'Đăng xuất',
      danger: true,
      iconName: EIconName.Logout,
      onClick: handleOpenModalLogout,
    },
  ];

  return (
    <div className="Header">
      <div className="container">
        <div className="Header-wrapper flex items-center justify-between">
          <div className="Header-wrapper-item">
            {isMobile ? (
              <Button styleType={EButtonStyleType.GALLERY} iconName={EIconName.Menu} onClick={onOpenMenu} />
            ) : (
              <div className="Admin-title">
                {dataMenuBar({ userData: myProfileState, vovUserData: vovMyProfileState }).find((item) =>
                  item.activePaths.includes(pathname),
                )?.title || 'Phần mềm chuyển đổi giọng nói thành văn bản'}
              </div>
            )}
          </div>
          <div className="Header-wrapper-item">
            <Row gutter={[8, 8]}>
              <Col>
                <DropdownMenu options={dataSettingsDropdown} placement="bottomRight">
                  <div className="Header-account flex items-center">
                    <Avatar image={myProfileState?.avatar_url} />
                    <div className="Header-account-name">
                      {generateGreetings()},{' '}
                      <strong>
                        {myProfileState?.last_name} {myProfileState?.first_name}
                      </strong>
                    </div>
                    <Icon name={EIconName.ChevronDown} color={EIconColor.DOVE_GRAY} />
                  </div>
                </DropdownMenu>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <ModalConfirm
        type={EBannerType.INFO}
        visible={visibleModalLogout}
        title="Đăng Xuất"
        content="Bạn có chắc chắn muốn đăng xuất?"
        confirmButtonProps={{ title: 'Đăng Xuất' }}
        onClose={handleCloseModalLogout}
        onSubmit={handleLogout}
      />
    </div>
  );
};

export default Header;
