import { EIconName } from '@/components/Icon';
import { Paths } from '@/pages/routers';

import { TMenuBar } from './SideBar.types';
import { TUser, TVovUser } from '@/common/models';
import { EVovRole } from '@/common/enums';

export const dataMenuBar = ({ vovUserData }: { userData?: TUser; vovUserData?: TVovUser }): TMenuBar[] => [
  {
    id: 'danh-sach-ghi-am',
    title: 'Danh sách ghi âm',
    iconName: EIconName.Microphone,
    link: Paths.Dashboard,
    activePaths: [Paths.Dashboard],
    disabled: false,
    hide: false,
  },
  {
    id: 'chuyen-muc-ghi-am',
    title: 'Chuyên mục ghi âm',
    iconName: EIconName.Category,
    link: Paths.Category,
    activePaths: [Paths.Category],
    disabled: false,
    hide: vovUserData?.quyen !== EVovRole.ADMIN,
  },
  {
    id: 'cau-hinh-dia-chi-ip',
    title: 'Cấu hình địa chỉ IP',
    iconName: EIconName.Server,
    link: Paths.Dashboard,
    activePaths: [],
    disabled: true,
    hide: vovUserData?.quyen !== EVovRole.ADMIN,
  },
  {
    id: 'giay-phep-su-dung',
    title: 'Giấy phép sử dụng',
    iconName: EIconName.Copyright,
    link: Paths.Dashboard,
    activePaths: [],
    disabled: true,
    hide: vovUserData?.quyen !== EVovRole.ADMIN,
  },
  {
    id: 'cau-hinh-he-thong',
    title: 'Cấu hình hệ thống',
    iconName: EIconName.Settings,
    link: Paths.Dashboard,
    activePaths: [],
    disabled: true,
    hide: vovUserData?.quyen !== EVovRole.ADMIN,
  },
];
