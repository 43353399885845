import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import { TAdminProps } from '@/layouts/Admin/Admin.types';
import Header from '@/containers/Header';
import Helpers from '@/services/helpers';
import { getMyProfileAction, vovGetMyProfileAction } from '@/redux/actions';
import SideBar from '@/containers/SideBar';

import './Admin.scss';
import { TRootState } from '@/redux/reducers';

const Admin: React.FC<TAdminProps> = ({ children }) => {
  const dispatch = useDispatch();
  const atk = Helpers.getAccessToken();
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 991 });

  const myProfileState = useSelector((state: TRootState) => state.authReducer.getMyProfileResponse);

  useEffect(() => {
    if (myProfileState) {
      dispatch(vovGetMyProfileAction.request({ params: { userId: myProfileState?.vov_user_id } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myProfileState]);

  useEffect(() => {
    if (atk) {
      dispatch(getMyProfileAction.request({}));
    }
  }, [atk, dispatch]);

  useEffect(() => {
    if (isMobile) setVisibleMenu(false);
  }, [isMobile]);

  return (
    <div className={classNames('Admin', { mobile: isMobile })}>
      <div className="Admin-header">
        <Header isMobile={isMobile} onOpenMenu={(): void => setVisibleMenu(true)} />
      </div>
      {isMobile && (
        <div
          className={classNames('Admin-sidebar-overlay', { active: visibleMenu })}
          onClick={(): void => setVisibleMenu(false)}
        />
      )}
      <div className={classNames('Admin-sidebar', { active: visibleMenu })}>
        <SideBar />
      </div>
      <div className="Admin-body">
        <div className="container">{children}</div>
      </div>
      {/* <div className="Admin-footer text-right">
        Copyright ®2024 • Phần mềm được phát triển bởi <strong>Acazia Software Company</strong>. All Rights Reserved.
      </div> */}
    </div>
  );
};

export default Admin;
