import { all, takeLatest } from 'redux-saga/effects';

import { vovGetMyProfileAction, vovLoginAction } from '@/redux/actions';

import { vovGetMyProfileSaga } from './vov-get-my-profile';
import { vovLoginSaga } from './vov-login';

export default function* root(): Generator {
  yield all([
    takeLatest(vovGetMyProfileAction.request.type, vovGetMyProfileSaga),
    takeLatest(vovLoginAction.request.type, vovLoginSaga),
  ]);
}
