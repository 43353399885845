import { createReducer } from 'deox';

import { TVovGetMyProfileResponse, TVovLoginResponse } from '@/services/vov-api/vov-auth';
import { vovGetMyProfileAction, vovLoginAction } from '@/redux/actions';
import { vovGetMyProfileUpdateState } from './vov-get-my-profile';
import { vovLoginUpdateState } from './vov-login';

export type TVovAuthState = {
  vovGetMyProfileResponse?: TVovGetMyProfileResponse;
  vovLoginResponse?: TVovLoginResponse;
};

const initialState: TVovAuthState = {
  vovGetMyProfileResponse: undefined,
  vovLoginResponse: undefined,
};

const VovAuthReducer = createReducer(initialState, (handleAction) => [
  handleAction(vovGetMyProfileAction.success, vovGetMyProfileUpdateState),
  handleAction(vovLoginAction.success, vovLoginUpdateState),
]);

export default VovAuthReducer;
